<template>
	<aside class="horizontal-left-sidebar" :class="Sidebar_drawer ? `show-sidebar bg-${SidebarColor}` : `bg-${SidebarColor}`">
		<div class="container-fluid">
			<div :class="`bg-${logoColor} navbar-header d-block d-lg-none`">
				<LogoDark v-if="logoColor == 'white' || logoColor == 'purple'" />
				<LogoLight v-else />
				<span class="d-block d-lg-none close-sidebar-btn" @click="showMobileSidebar"><i class="ti ti-close"></i></span>
			</div>
			<div class="scrlbar">
				<ul class="nav mb-0">
					<template v-for="(route, i) in routes">
						<!---Sub Menu -->
						<li class="nav-item first-dd" :class="route.dd" :key="route.title" v-if="route.childern">
							<a class="nav-link d-flex">
								<!--<i  :class="route.icon"></i>-->
								<feather :type="route.icon"></feather>
								<span class="hide-text">{{ $t(route.i18n) || route.title }}</span>
								<i class="ti ti-angle-down dd-arrow hide-text"></i>
							</a>

							<ul class="nav flex-column sub-menu" :class="`bg-${SidebarColor}`">
								<template v-for="(subroute, i) in route.childern">
									<li class="nav-item" :key="i" @click="showMobileSidebar">
										<router-link class="nav-link d-flex" :to="`${subroute.to}`">
											<i :class="subroute.icon"></i>
											<span class="hide-text">{{ subroute.title }}</span>
										</router-link>
									</li>
								</template>
							</ul>
						</li>
						<!---Single Menu -->
						<li class="nav-item" :key="i" v-if="!route.childern && !route.header" @click="showMobileSidebar">
							<router-link :to="`${route.to}`" class="nav-link d-flex">
								<!--<i  :class="route.icon"></i>-->
								<feather :type="route.icon"></feather>
								<span class="hide-text">{{ route.title }}</span>
							</router-link>
						</li>
					</template>
				</ul>
			</div>
		</div>
	</aside>
</template>

<script>
import LogoDark from "../logo-dark/LogoDark";
import LogoLight from "../logo-light/LogoLight";
import { mapState, mapGetters } from "vuex";
import SidebarData from "./SidebarData";
export default {
	name: "HorizontalSidebar",
	components: {
		LogoDark,
		LogoLight,
	},
	data: () => ({
		initialShow: true,
		routes: SidebarData,
	}),
	computed: {
		...mapState("theme", ["logoColor", "Sidebar_drawer", "SidebarColor"]),
		...mapGetters({
			authorizations: "auth/getAuthorizations",
		}),
		routes() {
			return [
				{ header: "Total OCS" },
				// { header: "Total App", icon: "mdi mdi-dots-horizontal" },
				{
					id: "33",
					icon: "home",
					title: "Home",
					to: "/home",
					active: true,
				},
				{
					id: "0",
					icon: "grid",
					title: "Dashboard",
					to: "/dashboard",
					active: this.authorizations.includes("/Dashboards"),
				},
				{
					id: "1",
					icon: "user",
					title: "Customers",
					active: ["/Profiles-GetAll", "/CustomerDocumentTypes-GetAll", "/CustomerTypes-GetAll"].some((string) => this.authorizations.includes(string)),
					childern: [
						{
							icon: "mdi mdi-account-multiple",
							title: "List",
							to: "/customers",
							active: this.authorizations.includes("/Profiles-GetAll"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Types",
							to: "/general/customerReasonsTypes",
							active: this.authorizations.includes("/CustomerTypes-GetAll"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Documents",
							to: "/customers/documentsTypes",
							active: this.authorizations.includes("/CustomerDocumentTypes-GetAll"),
						},
					],
				},
				{
					id: "2",
					icon: "dollar-sign",
					title: "Loans",
					i18n: "Loans",
					name: "/loans/",
					active: ["/LoanApplications-GetAll", "/Rules-GetAll", "/LoanTypes-GetAll"].some((string) => this.authorizations.includes(string)),
					childern: [
						{
							icon: "mdi mdi-file-tree",
							title: "Applications",
							to: "/loans/applications",
							active: this.authorizations.includes("/LoanApplications-GetAll"),
						},
						{
							icon: "mdi mdi-calculator",
							title: "Rules",
							to: "/loans/rules",
							active: this.authorizations.includes("/Rules-GetAll"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Types",
							to: "/loans/types",
							active: this.authorizations.includes("/LoanTypes-GetAll"),
						},
					],
				},
				{
					id: "8",
					icon: "mdi mdi-account-multiple-plus",
					title: "Partners",
					name: "/loans-affiliates/",
					active: ["/LoanApplications-GetAll", "/Rules-GetAll", "/LoanTypes-GetAll", "/Profiles-GetAllToPartners"].some((string) =>
						this.authorizations.includes(string)
					),
					childern: [
						{
							icon: "mdi mdi-file-tree",
							title: "Index",
							to: "/loans-affiliates/list",
							active: this.authorizations.includes("/Profiles-GetAllToPartners"),
						},
						{
							icon: "mdi mdi-calculator",
							title: "Calculator",
							to: "/loans-affiliates/calculator",
							active: this.authorizations.includes("/Profiles-GetAllToPartners") && this.user.Roles[0] != "Admin",
						},
					],
				},
				{
					id: "5",
					icon: "dollar-sign",
					title: "Banks Screening",
					name: "/banks-screening/",
					active: ["/Banks-GetAll"].some((string) => this.authorizations.includes(string)),
					childern: [
						{
							icon: "mdi mdi-file-tree",
							title: "List",
							to: "/banks-screening/list",
							active: this.authorizations.includes("/Banks-GetAll"),
						},
					],
				},
				{
					id: "3",
					icon: "settings",
					title: "General",
					i18n: "General",
					name: "/general/",
					active: ["/Banks-GetAll", "/Countries-GetAll", "/Cities-GetAll", "/States-GetAll"].some((string) => this.authorizations.includes(string)),
					childern: [
						{
							icon: "mdi mdi-bank",
							title: "Banks",
							to: "/general/banks",
							active: this.authorizations.includes("/Banks-GetAll"),
						},
						{
							icon: "mdi mdi-map",
							title: "Countries",
							to: "/general/countries",
							active: this.authorizations.includes("/Countries-GetAll"),
						},
						{
							icon: "mdi mdi-city",
							title: "Cities",
							to: "/general/cities",
							active: this.authorizations.includes("/Cities-GetAll"),
						},
						{
							icon: "mdi mdi-map-marker-multiple",
							title: "States",
							to: "/general/states",
							active: this.authorizations.includes("/States-GetAll"),
						},
					],
				},
				{
					id: "12",
					icon: "lock",
					title: "Authentication",
					i18n: "Authentication",
					name: "/authentication/",
					active: ["/Roles-GetAll", "/Users-GetAll"].some((string) => this.authorizations.includes(string)),
					childern: [
						{
							icon: "mdi mdi-account-multiple",
							title: "Users",
							to: "/security/users",
							active: this.authorizations.includes("/Users-GetAll"),
						},
						{
							icon: "mdi mdi-account-convert",
							title: "Roles",
							to: "/security/roles",
							active: this.authorizations.includes("/Roles-GetAll"),
						},
					],
				},
			];
		},
	},
	methods: {
		showMobileSidebar() {
			this.$store.commit("theme/SET_SIDEBAR_DRAWER", false);
		},
	},
};
</script>

<style></style>
