<template>
	<aside class="left-sidebar" :class="Sidebar_drawer ? `show-sidebar bg-${SidebarColor}` : `bg-${SidebarColor}`">
		<div :class="`bg-${logoColor} navbar-header`">
			<LogoDark v-if="logoColor == 'white' || logoColor == 'purple'" />
			<LogoLight v-else />
			<span class="d-block d-lg-none close-sidebar-btn" @click="showMobileSidebar"><i
					class="ti ti-close"></i></span>
		</div>

		<VuePerfectScrollbar class="scrlbar">
			<ul class="nav flex-column mb-0">
				<template v-for="(route, i) in routes">
					<!---Menu Title -->
					<li class="nav-item" :key="route.header" v-if="route.header && route.active">
						<div class="d-flex nav-small-cap">
							<i :class="route.icon"></i>
							<span class="hide-text">{{ route.header }}</span>
						</div>
					</li>
					<!---Sub Menu -->
					<li class="nav-item" :key="route.title" v-if="route.childern && route.active">
						<a class="nav-link d-flex" v-b-toggle="route.id">
							<feather :type="route.icon"></feather>
							<span class="hide-text">{{ $t(route.i18n) || route.title }}</span>
							<i class="ti ti-angle-down dd-arrow ml-auto hide-text"></i>
						</a>
						<b-collapse :id="route.id" accordion="sidebar-accordion"
							:visible="$route.path == `${route.name + $route.name.replace(/\s+/g, '-').toLowerCase()}`">
							<ul class="nav flex-column sub-menu">
								<template v-for="(subroute, i) in route.childern">
									<li class="nav-item" :key="i" v-if="subroute.active">
										<router-link class="nav-link d-flex" :to="`${subroute.to}`">
											<i :class="subroute.icon"></i>
											<span class="hide-text">{{ subroute.title }}</span>
										</router-link>
									</li>
								</template>
							</ul>
						</b-collapse>
					</li>
					<!---Single Menu -->
					<li class="nav-item" :key="i" v-if="!route.childern && !route.header && route.active">
						<router-link :to="`${route.to}`" class="nav-link d-flex">
							<feather :type="route.icon"></feather>
							<span class="hide-text">{{ $t(route.i18n) || route.title }}</span>
						</router-link>
					</li>
				</template>
			</ul>
		</VuePerfectScrollbar>
	</aside>
</template>

<script>
import LogoDark from "../logo-dark/LogoDark";
import LogoLight from "../logo-light/LogoLight";
import { mapState, mapGetters } from "vuex";
// import SidebarData from "./SidebarData";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
	name: "VerticalSidebar",
	components: {
		LogoDark,
		LogoLight,
		VuePerfectScrollbar,
	},
	data: () => ({
		initialShow: true,
		// routes: SidebarData,
	}),
	computed: {
		...mapState("theme", ["logoColor", "Sidebar_drawer", "SidebarColor"]),
		...mapGetters({
			authorizations: "auth/getAuthorizations",
			user: "auth/getUser",
		}),
		check() {
			return console.log(this.$router.history.current);
		},

		routes() {
			return [
				{ header: "Total OCS" },
				// { header: "Total App", icon: "mdi mdi-dots-horizontal" },
				{
					id: "33",
					icon: "home",
					title: "Home",
					to: "/home",
					active: true,
				},
				{
					id: "0",
					icon: "grid",
					title: "Dashboard",
					to: "/dashboard",
					active: this.authorizations.includes("/Dashboards"),
				},
				{
					id: "1",
					icon: "user",
					title: "Customers",
					active: ["/Profiles-GetAll", "/CustomerDocumentTypes-GetAll", "/CustomerTypes-GetAll"].some((string) => this.authorizations.includes(string)),
					childern: [
						{
							icon: "mdi mdi-account-multiple",
							title: "All Customers",
							to: "/customers",
							active: this.authorizations.includes("/Profiles-GetAll"),
						},
						{
							icon: "mdi mdi-account-multiple",
							title: "Active Customers",
							to: "/customers?status=active",
							active: this.authorizations.includes("/Profiles-GetAll"),
						},
						{
							icon: "mdi mdi-account-multiple",
							title: "Inactive Customers",
							to: "/customers?status=inactive",
							active: this.authorizations.includes("/Profiles-GetAll"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Types",
							to: "/general/customerReasonsTypes",
							active: this.authorizations.includes("/CustomerTypes-GetAll"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Documents",
							to: "/customers/documentsTypes",
							active: this.authorizations.includes("/CustomerDocumentTypes-GetAll"),
						},
					],
				},
				{
					id: "2",
					icon: "dollar-sign",
					title: "Loans",
					i18n: "Loans",
					name: "/loans/",
					active: ["/LoanApplications-GetAll", "/Rules-GetAll", "/LoanTypes-GetAll"].some((string) => this.authorizations.includes(string)),
					childern: [
						{
							icon: "mdi mdi-file-tree",
							title: "Applications",
							to: "/loans/applications",
							active: this.authorizations.includes("/LoanApplications-GetAll"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "New Loan",
							to: "/loans/new",
							active: this.authorizations.includes("/LoanApplications-GetAll"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Active Loans",
							to: "/loans/active-loans",
							active: this.authorizations.includes("/LoanApplications-GetAll"),
						},
						{
							icon: "mdi mdi-calculator",
							title: "Rules",
							to: "/loans/rules",
							active: this.authorizations.includes("/Rules-GetAll"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Types",
							to: "/loans/types",
							active: this.authorizations.includes("/LoanTypes-GetAll"),
						},
					],
				},
				{
					id: "8",
					icon: "user",
					title: "Partners",
					name: "/loans-affiliates/",
					active: ["/LoanApplications-GetAll", "/Rules-GetAll", "/LoanTypes-GetAll", "/Profiles-GetAllToPartners"].some((string) =>
						this.authorizations.includes(string)
					),
					childern: [
						{
							icon: "mdi mdi-file-tree",
							title: "Index",
							to: "/loans-affiliates/list",
							active: this.authorizations.includes("/Profiles-GetAllToPartners"),
						},
						{
							icon: "mdi mdi-calculator",
							title: "Calculator",
							to: "/loans-affiliates/calculator",
							active: this.authorizations.includes("/Profiles-GetAllToPartners") && this.user.Roles[0] != "Admin",
						},
					],
				},
				{
					id: "5",
					icon: "briefcase",
					title: "Banks Screening",
					name: "/banks-screening/",
					active: ["/Banks-GetAll"].some((string) => this.authorizations.includes(string)),
					childern: [
						{
							icon: "mdi mdi-file-tree",
							title: "List",
							to: "/banks-screening/list",
							active: this.authorizations.includes("/Banks-GetAll"),
						},
					],
				},
				{
					id: "3",
					icon: "settings",
					title: "General",
					i18n: "General",
					name: "/general/",
					active: ["/Banks-GetAll", "/Countries-GetAll", "/Cities-GetAll", "/States-GetAll"].some((string) => this.authorizations.includes(string)),
					childern: [
						{
							icon: "mdi mdi-bank",
							title: "Banks",
							to: "/general/banks",
							active: this.authorizations.includes("/Banks-GetAll"),
						},
						{
							icon: "mdi mdi-map",
							title: "Countries",
							to: "/general/countries",
							active: this.authorizations.includes("/Countries-GetAll"),
						},
						{
							icon: "mdi mdi-city",
							title: "Cities",
							to: "/general/cities",
							active: this.authorizations.includes("/Cities-GetAll"),
						},
						{
							icon: "mdi mdi-map-marker-multiple",
							title: "States",
							to: "/general/states",
							active: this.authorizations.includes("/States-GetAll"),
						},
					],
				},
				{
					id: "12",
					icon: "lock",
					title: "Authentication",
					i18n: "Authentication",
					name: "/authentication/",
					active: ["/Roles-GetAll", "/Users-GetAll"].some((string) => this.authorizations.includes(string)),
					childern: [
						{
							icon: "mdi mdi-account-multiple",
							title: "Users",
							to: "/security/users",
							active: this.authorizations.includes("/Users-GetAll"),
						},
						{
							icon: "mdi mdi-account-convert",
							title: "Roles",
							to: "/security/roles",
							active: this.authorizations.includes("/Roles-GetAll"),
						},
					],
				},
			];
		},
	},
	methods: {
		showMobileSidebar() {
			this.$store.commit("theme/SET_SIDEBAR_DRAWER", false);
		},
	},
};
</script>

<style></style>
